<template>
  <h1>{{ msg }}</h1>
</template>

<script>

export default {
    'name': 'FeaturesNoFoundView',
    data() {
        return {
            'msg': '抱歉，页面未找到，请联系项目开发人员!'
        };
    },
    'methods': {}
};
</script>

<style>

</style>
